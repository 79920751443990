import React from "react";
import styled from 'styled-components';

import { Paragraph } from "../theme/typography";

import IntroBackgroundVertical from '../images/homepage/ed-bgr-vertical.webp';
import IntroBackgroundHorizontal from '../images/homepage/ed-bgr-horizontal.webp';

const SyledIntroBlock = styled.section`
	background: 
		linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0) 100%),
		url(${IntroBackgroundVertical}) no-repeat center/cover;
	background-color: ${(props) => props.theme.colors.neutral2};
	color: ${(props) => props.theme.colors.neutral10};
	display: flex;
	align-items: flex-end;
	min-height: calc(100dvh - 3rem);
	scroll-snap-align: start;
	
	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		background: 
			linear-gradient(90deg, rgba(0, 0, 0, 0.4) 15%, rgba(0, 0, 0, 0) 65%),
			url(${IntroBackgroundHorizontal}) no-repeat center/cover;
		background-color: #212121;
		justify-content: flex-start;
		text-align: left;
	}
`;

const ContentContainer = styled.div`
	box-sizing: border-box;
	padding: 1.75rem 1.5rem;
	margin: 0 auto;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.second}) {
		padding: 1.75rem 3rem;
		margin-left: 0;
		max-width: 42rem;
	}

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		padding: 1.75rem 3rem;
		margin-left: 0;
		max-width: 42rem;
	}
`;

const Greeting = styled.h2`
	${(props) => props.theme.typography.headingL};

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.second}) {
		${(props) => props.theme.typography.headingXL};
	}

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.sixth}) {
		${(props) => props.theme.typography.heading3XL};
	}
`;

const FirstSentence = styled.h3`
	${(props) => props.theme.typography.headingXS};

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.second}) {
		${(props) => props.theme.typography.headingXL};
	}
`;

export class IntroBlock extends React.Component {

	render() {
		return (
			<SyledIntroBlock>
				<ContentContainer>
					<Greeting>Hey, I'm Ed!</Greeting>
					<FirstSentence>I started Tressym because I love designing FPV racing drones.</FirstSentence>
					<Paragraph>
						All my designs are open source. You can download, tweak, or improve them as you like.
					</Paragraph>
					<Paragraph>
						I’m excited to see what you come up with!<br/>Let’s invent cool new stuff together!
					</Paragraph>
				</ContentContainer>
			</SyledIntroBlock>
		);
	}
}