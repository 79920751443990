import React from "react";
import styled from 'styled-components';

import {
	HeroParagraph,
	Kicker,
	MainTitle
} from "../theme/typography";
import Button from "./Button";

import ServalBackgroundVertical from '../images/homepage/serval-block-bgr-vertical.webp';
import ServalBackgroundHorizontal from '../images/homepage/serval-block-bgr-horizontal.webp';

const StyledServalBlock = styled.div`
	box-sizing: border-box;
	background: 
		linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%),
		url(${ServalBackgroundVertical}) no-repeat center/cover;
	background-color: #212121;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 1.75rem 1.5rem;
	min-height: calc(100dvh - 4.875rem);
	text-align: center;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		background: 
			linear-gradient(0deg, rgba(0, 0, 0, 0.4) 15%, rgba(0, 0, 0, 0) 65%),
			url(${ServalBackgroundHorizontal}) no-repeat center/cover;
		padding: 1.75rem 3rem;
	}
`;

const ContentContainer = styled.div``;

const ServalKicker = styled(Kicker)`
	color: ${(props) => props.theme.colors.yellow6};
	margin: 0.5rem 0;
`;

const ButtonContainer = styled.div`
	margin-top: 2rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		display: flex;
		justify-content: center;
	}
`;

const LearnMoreButton = styled(Button)`

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		flex: 0 0 auto;
	}
`;

export class ServalBlock extends React.Component {

	render() {
		return (
			<StyledServalBlock>
				<ContentContainer>
					<ServalKicker>Introducing</ServalKicker>
					<MainTitle>Serval</MainTitle>
					<HeroParagraph>A featherweight 5-inch racing frame.<br/>Lighter than <em>light,</em> sturdier than <em>ultralight.</em></HeroParagraph>
					<ButtonContainer>
						<LearnMoreButton label="Learn more" link="/frames/serval"/>
					</ButtonContainer>
				</ContentContainer>
			</StyledServalBlock>
		);
	}
}