import React from "react";
import styled from 'styled-components';

import Button from "./Button";
import LabeledData from "./LabeledData";
import { LabeledDataRow } from "./LabeledDataRow";
import { HeroParagraph, MainTitle } from "../theme/typography";

import ServalHeaderBackgroundVertical from '../images/homepage/serval-header-block-bgr-vertical.webp';
import ServalHeaderBackgroundHorizontal from '../images/homepage/serval-header-block-bgr-horizontal.webp';

const StyledServalBlock = styled.div`
	background: 
		linear-gradient(0deg, rgba(0, 0, 0, 0.5) 15%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.5) 100%),
		url(${ServalHeaderBackgroundVertical}) no-repeat center/cover;
	background-color: #212121;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 1.75rem 1.5rem;
	min-height: calc(100dvh - 4.875rem);
	text-align: center;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		align-items: flex-start;
		background: 
			linear-gradient(90deg, rgba(0, 0, 0, 0.4) 15%, rgba(0, 0, 0, 0) 65%),
			url(${ServalHeaderBackgroundHorizontal}) no-repeat center/cover;
		background-color: #212121;
		justify-content: center;
		padding: 1.75rem 3rem;
		text-align: left;
	}
`;

const TopContent = styled.div``;

const BottomContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		align-items: flex-start;
		width: 28rem;
	}
`;

const PriceRow = styled(LabeledDataRow)`
	width: 100%;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		max-width: 24rem;
	}

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		max-width: 28rem;
	}
`;

const ButtonContainer = styled.div`
	margin-top: 1.5rem;
	width: 100%;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		display: flex;
		justify-content: center;
	}

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		justify-content: flex-start;
	}
`;

const BuyButton = styled(Button)`

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		flex: 0 0 auto;
	}
`;


export class ServalHeaderBlock extends React.Component {

	render() {
		return (
			<StyledServalBlock>
				<TopContent>
					<MainTitle>Serval</MainTitle>
					<HeroParagraph>A featherweight 5-inch racing frame.<br/>Lighter than <em>light,</em> sturdier than <em>ultralight.</em></HeroParagraph>
				</TopContent>
				<BottomContent>
					<PriceRow>
						<LabeledData
							label="Frame"
							data="€37"
							description="15 000 HUF"
						/>
						<LabeledData
							label="TPU parts"
							data="€9"
							description="3600 HUF"
						/>
						<LabeledData
							label="+4 Arms"
							data="€25"
							description="10 000 HUF"
						/>
					</PriceRow>
					<ButtonContainer>
						<BuyButton label="Buy a Serval" link="https://forms.gle/LBDyttYL4AQEjvGn6"/>
					</ButtonContainer>
				</BottomContent>
			</StyledServalBlock>
		);
	}
}