import React from "react";
import styled from 'styled-components';

import Button from "./Button";
import { Paragraph } from "../theme/typography";

const StyledDiscordBlock = styled.section`
	background: ${(props) => props.theme.colors.neutral10};
	color: ${(props) => props.theme.colors.neutral2};
	scroll-snap-align: start;
`;

const ContentContainer = styled.div`
	box-sizing: border-box;
	padding: 1.75rem 1.5rem;
	margin: 0 auto;
	max-width: 32rem;
`;

const Kicker = styled.h6`
	${(props) => props.theme.typography.heading2XS};
	color: ${(props) => props.theme.colors.yellow4};
	margin: 0.5rem 0;
	text-transform: uppercase;
`;

const ButtonContainer = styled.div`
	margin-top: 2rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		display: flex;
	}
`;

const Heading = styled.h3`
	${(props) => props.theme.typography.headingM};
	margin: 1.5rem auto;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.sixth}) {
		${(props) => props.theme.typography.headingL};
	}
`;

const FlexButton = styled(Button)`
	flex: 0 0 auto;
`;

export class DiscordBlock extends React.Component {

	render() {
		return (
			<StyledDiscordBlock>
				<ContentContainer>
					<Kicker>Interested?</Kicker>
					<Heading>Join our Discord!</Heading>
					<Paragraph>Ask&nbsp;questions, keep&nbsp;up with development news, and tell&nbsp;us what&nbsp;you&nbsp;think!</Paragraph>
					<ButtonContainer>
						<FlexButton label="Accept invitation" link="https://discord.gg/rRCda9KUWR"/>
					</ButtonContainer>
				</ContentContainer>
			</StyledDiscordBlock>
		);
	}
}