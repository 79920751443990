import React from "react";
import styled from 'styled-components';
import { Link } from "react-router-dom";

// import iconMenu from '../images/icon-menu.svg';

const StyledHeaderFixed = styled.div`
	background: ${(props) => props.theme.colors.yellow6};
	color: ${(props) => props.theme.colors.yellow1};
	display: flex;
	height: 3rem;
	position: fixed;
	top: 0px;
	width: 100%;
`;

const HeaderLogoWordmark = styled(Link)`
	color: ${(props) => props.theme.colors.yellow1};
	flex: 1 0 0;
	font-family: "Infiltrator";
	font-size: 2rem;
	line-height: 2rem;
	padding: 0.5rem 1rem;
	text-decoration: none;

	&:hover {
		background-color: ${(props) => props.theme.colors.yellow5};
	}
`;

/* const MenuIconButton = styled.button`
	background: ${(props) => props.theme.colors.yellow6};
	border: none;
	flex: 0 0 4.5rem;
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;

	img {
		width: 2rem;
	}
`; */

const StyledHeaderTagline = styled.p`
	${(props) => props.theme.typography.heading2XS};

	background: ${(props) => props.theme.colors.yellow6};
	color: ${(props) => props.theme.colors.yellow1};
	display: flex;
	justify-content: space-between;
	padding: 0 1rem 0.75rem;
	margin: 0;
	text-transform: uppercase;
`;

export class HeaderFixed extends React.Component {

	render() {
		return (
			<StyledHeaderFixed>
				<HeaderLogoWordmark to="/">
					Tressym
				</HeaderLogoWordmark>
				{/*<MenuIconButton>
					<img src={iconMenu} alt="Menu" />
				</MenuIconButton>*/}
			</StyledHeaderFixed>
		);
	}
}

export class HeaderTagline extends React.Component {

	render() {
		return (
			<StyledHeaderTagline>Drone Labs</StyledHeaderTagline>
		);
	}
}