import React from "react";
import styled from "styled-components";

import { Kicker } from "../theme/typography";

const LabeledData = styled.div``;

const DataLabel = styled(Kicker)`
	color: ${(props) =>
		props.colorContext === "light"
			? props.theme.colors.yellow4
			: props.theme.colors.yellow6};
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
`;

const DataL = styled.h3`
	${(props) => props.theme.typography.headingL};
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
`;

const DataM = styled.h3`
	${(props) => props.theme.typography.headingM};
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
`;

const Description = styled.p`
	${(props) => props.theme.typography.body2XS};
	color: ${(props) =>
		props.colorContext === "light"
			? props.theme.colors.neutral4
			: props.theme.colors.neutral6};
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
`;

class ButtonLink extends React.Component {
	render() {
		const { label, data, description, colorContext = "dark", size = "l" } = this.props;

		// Dynamically pick the component based on size
		const DataComponent = size === "m" ? DataM : DataL;

		return (
			<LabeledData>
				{label && <DataLabel colorContext={colorContext}>{label}</DataLabel>}
				{data && <DataComponent>{data}</DataComponent>}
				{description && <Description colorContext={colorContext}>{description}</Description>}
			</LabeledData>
		);
	}
}

export default ButtonLink;
