import React from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';

const StyledButton = styled(Link)`
	${(props) => props.theme.typography.heading2XS};

	background-color: ${(props) => props.theme.colors.purple4};
	border-radius: 0.25rem;
	color: ${(props) => props.theme.colors.neutral10};
	display: block;
	padding: 1rem 1.5rem;
	text-decoration: none;
	text-transform: uppercase;
	text-align: center;

	::hover {
		background-color: ${(props) => props.theme.colors.purple3};
	}
`;

class Button extends React.Component {

	render() {
		return (
			<StyledButton className={this.props.className} to={this.props.link}>
				{this.props.label}
			</StyledButton>
		);
	}
}

export default Button;