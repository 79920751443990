import styled from 'styled-components';

export const Paragraph = styled.p`
	${(props) => props.theme.typography.bodyXS};
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.second}) {
		${(props) => props.theme.typography.bodyS};
	}
`;

export const ParagraphSmall = styled.p`
	${(props) => props.theme.typography.body2XS};
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.second}) {
		${(props) => props.theme.typography.bodyXS};
	}
`;

export const HeroParagraph = styled.p`
	${(props) => props.theme.typography.bodyXS};
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.second}) {
		${(props) => props.theme.typography.bodyM};
		margin-bottom: 2rem;
		margin-top: 1.5rem;
	}
`;

export const Kicker = styled.p`
	${(props) => props.theme.typography.heading2XS};
	text-transform: uppercase;
`;


export const MainTitle = styled.h2`
	${(props) => props.theme.typography.headingInfiltratorXL};
	margin-bottom: -1rem;
	margin-top: 0;
	
	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		${(props) => props.theme.typography.headingInfiltrator3XL};
		margin-bottom: -1rem;
	}
	
	@media only screen and (min-width: ${(props) => props.theme.breakpoints.sixth}) {
		${(props) => props.theme.typography.headingInfiltrator4XL};
		margin-bottom: -1rem;
	}
`;