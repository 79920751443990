import React from 'react';

import { ThemeProvider, styled } from 'styled-components';
import { theme } from './theme/aikya';

import { HeaderFixed, HeaderTagline } from './components/Header';
import { DiscordBlock } from './components/DiscordBlock';
import { ServalHeaderBlock } from './components/ServalHeaderBlock';
import { ServalTextBlock } from './components/ServalTextBlock';
import { ServalSpecBlock } from './components/ServalSpecBlock';
import { ServalOpenSourceBlock } from './components/ServalOpenSourceBlock';
const ServalPageWrapper = styled.div`
	margin-top: 3rem;
	height: calc(100vh - 3rem);
	overflow-y: scroll;
	scroll-snap-type: y proximity;
`;

export class ServalPage extends React.Component {

    render() {

        return (
			<ThemeProvider theme={theme}>
				<HeaderFixed />
				<ServalPageWrapper>
					<HeaderTagline />
					<ServalHeaderBlock />
					<ServalTextBlock />
					<ServalOpenSourceBlock />
					<ServalSpecBlock />
					<DiscordBlock />
				</ServalPageWrapper>
			</ThemeProvider>
		)
	}
}