import styled from 'styled-components';

export const LabeledDataRow = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 1rem;
	text-align: center;

	> :last-child {
		text-align: right;
	}

	> :first-child {
		text-align: left;
	}
`;