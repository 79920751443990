import React from 'react';

import { ThemeProvider, styled } from 'styled-components';
import { theme } from './theme/aikya';

import { HeaderFixed, HeaderTagline}  from './components/Header';
import { IntroBlock } from './components/IntroBlock';
import { DiscordBlock } from './components/DiscordBlock';
import { ServalBlock } from './components/ServalBlock';

const HomepageWrapper = styled.div`
	margin-top: 3rem;
	height: calc(100vh - 3rem);
	overflow-y: scroll;
	scroll-snap-type: y proximity;
`;

export class Homepage extends React.Component {

    render() {

        return (
			<ThemeProvider theme={theme}>
				<HeaderFixed />
				<HomepageWrapper>
					<HeaderTagline />
					<ServalBlock />
					<IntroBlock />
					<DiscordBlock />
				</HomepageWrapper>
			</ThemeProvider>
		)
	}
}