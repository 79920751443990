import React from "react";
import styled from 'styled-components';

import LabeledData from "./LabeledData";
import { LabeledDataRow } from "./LabeledDataRow";
import { Paragraph } from "../theme/typography";

const StyledServalTextBlock = styled.section`
	background: ${(props) => props.theme.colors.neutral10};
	color: ${(props) => props.theme.colors.neutral2};
	scroll-snap-align: start;
`;

const ContentContainer = styled.div`
	box-sizing: border-box;
	min-height: calc(100dvh - 3rem);
	padding: 1.5rem;
	margin: 0 auto;
	max-width: 32rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	:first-child {
		margin-top: 0;
	}

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.sixth}) {
		min-height: auto;
	}
`;

const Heading = styled.h3`
	${(props) => props.theme.typography.headingL};
`;

const TopContent = styled.div``;

const BottomContent = styled.div``;

export class ServalTextBlock extends React.Component {

	render() {
		return (
			<StyledServalTextBlock>
				<ContentContainer>
					<TopContent>
						<Heading>Better durability, tiny weight</Heading>
						<Paragraph>The Serval has a stronger and more rigid body than most ultralights, with great resonance characteristics.</Paragraph>
						<Paragraph>Its wide top plate protects your HDZero VTX. Most ultralights expose your VTX, making midairs and crashes costlier.</Paragraph>
						<Paragraph>Carbon fiber epoxy can be twice as strong in the direction of its fibers. The Serval’s straight-lined design takes full advantage of this, maximizing durability where it counts.</Paragraph>
						<Paragraph>And still, a full racing drone built on a Serval frame is only about 1% heavier than an ultralight (465 g vs 460 g).</Paragraph>
					</TopContent>
					<BottomContent>
						<LabeledDataRow>
							<LabeledData
								colorContext="light"
								label="Weight"
								data="59.52 g"
							/>
							<LabeledData
								colorContext="light"
								label="TPU weight"
								data="6.44 g"
							/>
						</LabeledDataRow>
					</BottomContent>
				</ContentContainer>
			</StyledServalTextBlock>
		);
	}
}